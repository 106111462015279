.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.image {
  width: 50px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 50px;
    object-fit: contain;
  }
}

.missing-img-icon {
  display: block;
  margin-right: 16px;
  font-size: 42px;
}
