@use "sass:color";
@use "../variables";

// Antd Button Overrides

$btn-dangerous-color: #ff4d4f;
$btn-icon-bg-base: #192858;

/**
  For some reason all AntD icons in Forge are ~2px higher than they should be.
  I can't find where this line-height difference is coming from. The computed
  CSS as compared to AntD's own docs site look identical.
  Possible culprit may be Bootstrap(?)
  Specifically setting the line height to 1.75 (instead of the default 1.57) seems to work.
*/
$ant-icon-line-height: 1.75;

.ant-btn {
  // colors set in theme.less

  &:hover {
    color: variables.$color-text-primary;
    border-color: variables.$color-text-extra-light;
  }

  &.ant-btn-primary,
  &.ant-btn-icon-only.ant-btn-primary {
    // colors set in theme.less

    &:hover,
    &:focus {
      color: #fff !important;
      border-color: variables.$color-primary-hover !important;
      background-color: variables.$color-primary-hover !important;
    }

    &.ant-btn-background-ghost {
      background-color: transparent !important;

      &:hover,
      &:focus {
        color: #fff !important;
        border-color: variables.$color-primary !important;
        background-color: variables.$color-primary !important;
      }
    }
  }

  &.ant-btn-background-ghost {
    // colors set in theme.less

    &:hover,
    &:focus {
      color: color.adjust(variables.$color-primary, $lightness: -4%);
      background-color: rgba(variables.$color-primary, 0.18) !important;
      border-color: transparent;
    }
  }

  &.ant-btn-link {
    &:hover,
    &:focus {
      color: color.adjust(variables.$color-primary, $lightness: -12%);
      border-color: transparent;
    }

    &:disabled {
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }

  // Disabled state overrides
  &[disabled],
  &.ant-btn-primary[disabled],
  &.ant-btn-background-ghost[disabled],
  &.ant-btn-primary.ant-btn-background-ghost[disabled] {
    color: rgba(#000, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;

    &:hover,
    &:focus {
      color: rgba(#000, 0.25) !important;
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
    }
  }

  // Danger button
  &.ant-btn-dangerous {
    &:hover,
    &:focus {
      color: #fff !important;
      border-color: $btn-dangerous-color;
      background-color: $btn-dangerous-color;
    }

    &.ant-btn-primary {
      color: #fff;

      &:hover,
      &:focus {
        border-color: color.adjust($btn-dangerous-color, $lightness: -18%);
        background-color: color.adjust($btn-dangerous-color, $lightness: -18%);
      }
    }
  }

  // Small
  &.ant-btn-sm {
    line-height: $ant-icon-line-height;
  }

  // Icon buttons
  &.ant-btn-icon-only {
    color: #818792;
    line-height: $ant-icon-line-height;
    border-color: transparent;
    background-color: rgba($btn-icon-bg-base, 0.07);

    &:hover,
    &:focus {
      color: color.adjust(#818792, $lightness: -16%) !important;
      border-color: transparent !important;
      background-color: rgba($btn-icon-bg-base, 0.13);
    }

    &.ant-btn-primary {
      color: #fff;
      background-color: variables.$color-primary;
    }

    &.ant-btn-background-ghost {
      color: variables.$color-primary;
    }

    &.ant-btn-link {
      color: variables.$color-text-muted;
      background-color: transparent;

      &:hover {
        color: variables.$color-text-muted;
        background-color: rgba($btn-icon-bg-base, 0.07);
      }

      &.ant-btn-dangerous {
        color: $btn-dangerous-color;

        &:hover,
        &:focus {
          color: $btn-dangerous-color !important;
          background-color: rgba($btn-dangerous-color, 0.09);
        }
      }

      &.ant-btn-dangerous:disabled {
        &.ant-btn-dangerous {
          color: rgba(0, 0, 0, 0.25);

          &:hover,
          &:focus {
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }
    }

    .ant-btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .anticon {
      line-height: $ant-icon-line-height;
    }
  }
}
