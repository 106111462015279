.container {
  display: flex;
  flex-direction: column;

  .container-with-metadata {
    line-height: 14px;
    margin-bottom: -2px;
  }

  .metadata {
    font-size: 10px;
    color: #cb8c8c;
    line-height: 15px;
    white-space: normal;
    overflow-wrap: break-word;
  }
}
