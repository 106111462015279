.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.image {
  width: 50px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 50px;
    object-fit: contain;
  }
}

.missing-img-icon {
  display: block;
  margin-right: 16px;
  font-size: 42px;
}

.product-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.ant-table-row):hover {
  .product-link {
    padding-right: 15px;
  }
}


.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px); // offset based on the width of the image plus the gap
}
