.ant-drawer.fixed-header {
  .ant-drawer-content,
  .ant-drawer-wrapper-body {
    width: inherit;
  }

  .ant-drawer-wrapper-body {
    position: relative;
    top: 55px;
    padding-bottom: 55px;
  }
}
