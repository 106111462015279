@use '../../../styles/variables';

.state-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        margin-right: 5px;
    }
}

.failure {
    color: variables.$danger-color;
  }
  
.neutral {
    color: variables.$change-set-manually-changes-proposed;
}

.success {
    color: variables.$change-set-completed-color;
}

.automatic-changes-proposed {
    color: variables.$change-set-scheduled-changes-proposed;
}
