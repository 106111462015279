@use '../../styles/variables';
@use '../../styles/mixins';

.centered-card-wrapper {
  @include mixins.full-content;

  display: flex;
  justify-content: center;
  align-items: center;

  .centered-card {
    @include mixins.box-shadow-surface-xs;

    background-color: #fff;
    font-weight: 500;
    text-align: center;
    padding: 3rem;
    max-width: 60%;
  }
}

.page-content-padded-sm {
  padding: 16px variables.$content-horiz-padding variables.$content-horiz-padding;
}

.page-content-padded-md {
  padding: 35px 25px;
}
