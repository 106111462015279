@use '../../../styles/variables';

$menu-item-height: 32px !important;
$submenu-item-height: 28px !important;

.branding-container {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  padding: 0 10px;

  .ds-logo-container {
    height: 30px;

    .logo-icon {
      color: white;
      font-size: 30px;
    }
  }

  .ds-logo-container-cobalt {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-icon {
      color: white;
    }

    &.collapsed {
      padding: 12px 0;
    }
  }

  .whitelabel-logo-container img {
    max-height: 48px;
    max-width: 100%;
  }
}

.divider {
  width: calc(100% - 22px);
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(#fff, 0.2);

  &.full-width {
    width: 100%;
    margin: 12px 0;
  }
}

:global(.ant-menu-inline-collapsed) {
  .divider {
    width: 50%;
  }
}

.sider-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.sider-menu {
  padding: 0 0 132px;
  font-size: 14px;

  &:global(.ant-menu-inline-collapsed) {
    width: variables.$sidebar-collapsed-width !important;

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      margin-left: 8px;
    }
  }

  :global(.anticon) {
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    font-size: 20px !important;
    vertical-align: middle !important;
    margin-bottom: 3px !important;
    opacity: 0.5;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    height: $menu-item-height;
    line-height: $menu-item-height;
    padding-left: 18px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    &:hover {
      :global(.anticon),
      span,
      i {
        opacity: 1 !important;
      }
    }
  }

  :global(.ant-menu-submenu-title) {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.07em;
    font-weight: 500;
    user-select: none;
  }

  :global(.ant-menu-submenu-open) {
    :global(.anticon) {
      opacity: 0.5 !important;
    }
  }

  :global(.ant-menu-item-selected),
  :global(.ant-menu-submenu-selected) {
    background: none !important;

    :global(.anticon),
    span {
      opacity: 1 !important;
    }
  }

  :global(.ant-menu-submenu) {
    ul {
      margin-left: 30px !important;
      width: auto !important;

      li {
        height: $submenu-item-height;
        line-height: $submenu-item-height;
      }
    }
  }

  :global(.ant-menu-item) {
    height: $menu-item-height;
    line-height: $menu-item-height;

    :global(.ant-menu-title-content) {
      transition: all 0s !important;
    }

    :global(.ant-menu-item-icon) svg {
      transition: all 0s !important;
    }
  }

  :global(.ant-menu-submenu-active) {
    color: #fff !important;
  }

  .organization-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
  }
}

.bottom-menu {
  position: absolute;
  bottom: 48px;
  width: 100%;
  background-color: variables.$color-background-sidebar;

  .sider-menu {
    padding-bottom: 0;
  }

  .divider {
    width: calc(100% - 20px);
    margin: 0 10px 12px;
  }
}

// Popover styles for submenu when nav is collapsed
.submenu-collapsed {
  ul {
    padding: 10px 0 !important;
    background: variables.$color-background-sidebar !important;
  }

  li {
    height: $submenu-item-height;
    line-height: $submenu-item-height;
    margin: 0 !important;
    background: none !important;

    a:hover {
      text-decoration: none;
    }
  }
}
