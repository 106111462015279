@use '../../../../../styles/variables';

.header-cell {
  &:global(.react-resizable) {
    position: relative;
    background-clip: padding-box;

    :global(.react-resizable-handle) {
      cursor: col-resize;
      padding: 0;
      width: 16px;
      height: 100%;
      right: -8px;
      z-index: 1;
      background-image: none;

      &::after {
        content: '';
        height: 100%;
        width: 2px;
        position: absolute;
        top: 0;
        left: 7px;
        background-color: #d2d4db;
        transition: all 0.25s;
        opacity: 0;
      }

      &:hover::after {
        background-color: variables.$color-primary;
      }
    }
  }

  &:hover {
    &:global(.react-resizable .react-resizable-handle) {
      &::after {
        opacity: 1;
      }
    }
  }
}
