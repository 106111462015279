@use 'sass:color';
@use '../variables';

.fg-form-row {
  display: flex;
  justify-content: space-between;

  .fg-form-control {
    margin-right: 20px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }
}

.fg-form-control {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 28px;
}

.fg-form-legend {
  font-size: variables.$font-size-medium;
  font-weight: 600;
  padding-bottom: 4px;
  margin-bottom: 12px;
  border-bottom: 1px solid variables.$gray-background-color;
}

.fg-control-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 8px;
}

.fg-control-divider {
  margin: 0 0 8px;
}

.fg-control-text {
  font-size: variables.$font-size-smaller;
  margin-top: 5px;

  &.floating {
    position: absolute;
    top: 2rem;
  }

  &.is-error {
    color: variables.$danger-color;
  }
}

.ant-input-group-wrapper {
  &.fg-control-error {
    .ant-input-group-addon {
      border: 1px solid variables.$danger-color;
      border-right: 0;
      background-color: color.adjust(variables.$danger-color, $lightness: 44.5%);
    }

    .ant-input {
      border: 1px solid variables.$danger-color;
    }
  }
}

.fg-control-error {
  .ant-select-selection,
  .ant-select-selector,
  &.ant-input,
  &.ant-input-number,
  &.ant-picker {
    border: 1px solid variables.$danger-color !important;
  }
}

.fg-form-metric {
  text-decoration: underline;
  font-weight: 600;
}

.fg-form-modifier {
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8em;
  letter-spacing: 0.1px;
}
