@use '../../styles/variables';

.logo {
  width: 40px;
  margin-bottom: 20px;
}

.header {
  font-size: 32px;
}

.cta {
  font-size: 18px;
  margin-bottom: 16px;
}
