@use '../../../styles/variables';
@use '../../../styles/mixins';

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.subtitle {
  font-size: 13px;
  font-weight: variables.$font-weight-normal;
  color: variables.$color-text-light;
  line-height: 1.2em;

  :global(.highlight) {
    color: variables.$color-text-primary;
    font-weight: 500;
  }
}

.title-footer {
  border-top: 1px solid #EAEAEA;
  margin-top: 12px;
  padding-top: 12px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}
