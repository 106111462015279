@use '../variables';

.ant-upload {
  &.ant-upload-select {
    text-align: center;
    width: 100%;
    background: rgba(variables.$color-primary, 0.075);
    border: 1px solid transparent;
    transition: all 200ms ease-in-out;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: rgba(variables.$color-primary, 0.09);
      border: 1px solid rgba(variables.$color-primary, 0.15);
    }
  }
}
