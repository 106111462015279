@use '../../../../../styles/variables';

.details-wrapper {
  display: flex;
  align-items: center;

  .details-wrapper-left {
    margin-right: 6px;
    display: flex;
    align-items: center;

    :global(.fg-default-tag) {
      color: variables.$color-details-field-text;
      background-color: variables.$color-details-tag-bg;
    }

    :global(.fg-default-tag),
    :global(.fg-active-tag) {
      padding: 3px 7px;
    }
  }
}
