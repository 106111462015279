@use "sass:color";
@use '../../styles/variables';

.more-icon {
  color: #8c9295 !important;
  position: absolute;
  transition: all 0.3s;
  height: 32px;
  width: 32px;
  background-color: variables.$white-background-color;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 2px 4px -3px rgba(0, 0, 0, 0.4),
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 0 5px 10px variables.$white-background-color;
  border: 1px solid rgba(0, 0, 0, 0.12);
  top: 5px;
  right: 5px;

  .is-editable & {
    color: variables.$color-primary !important;
    border: 1px solid rgba(variables.$color-primary, 0.5);

    &:hover {
      border-color: variables.$color-primary;
      background-color: color.adjust(variables.$color-primary, $lightness: 34%);
    }
  }
}

.retry-btn {
  color: variables.$color-primary !important;
}

.widget-config-explanation {
  max-width: 400px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.text-widget {
  :global(.ant-card-body) {
    padding-top: 0 !important;
    overflow-y: auto;
  }
}
