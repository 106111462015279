.home-icon-button {
    height: 24px;
    min-width: 24px !important;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    span[role="img"] {
        font-size: 12px;
    }

    &:hover {
        color: white !important;
    }
}