@use '../../../styles/variables';

.pending {
  color: variables.$color-confidence-uncertain;
  font-size: variables.$font-size-smaller;
}

.failure {
  color: variables.$danger-color;
  font-size: variables.$font-size-smaller;
}

.complete {
  color: variables.$success-color;
  font-size: variables.$font-size-smaller;
}

.container {
  display: flex;
  align-items: center;
}
