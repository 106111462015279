@use '../../styles/variables';
@use '../../styles/mixins';

.card {
  @include mixins.box-shadow-surface-xs;

  margin-bottom: variables.$row-padding;

  .show-on-hover span {
    opacity: 0;
    transform: translateX(5px);
    transition: all 0.3s;
  }

  &:hover {
    .show-on-hover span {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.title-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  .title {
    @include mixins.secondary-heading;
  }

  .sub-title {
    font-size: 13px;
    font-weight: variables.$font-weight-normal;
    color: variables.$color-text-light;
    line-height: 1.2em;
  }

  :global(.react-grid-item) & {
    .title {
      @include mixins.secondary-heading;

      margin-top: 6px;
    }
  }
}
