@use '../../styles/variables';

.background {
  background-color: rgba(0, 0, 0, 0.006);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

.container {
  position: absolute;
  top: 25%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 380px;
  padding: 30px 36px 15px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background-color: #fff;

  p {
    font-size: variables.$font-size-medium;
  }
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 160px;
  }
}
