.field {
    .keyword-link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

:global(.ant-table-row):hover {
    .keyword-link {
        padding-right: 15px;
    }
}
