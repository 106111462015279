@use 'sass:color';
@use '../variables';

.fg-icon-xs {
  font-size: 12px;
}

@mixin fg-tag {
  position: relative;
  // top: -1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 7px;
  border-radius: 4px;
  letter-spacing: 0.03em;
}

.fg-default-tag {
  @include fg-tag();

  color: #919499;
  background-color: #f0f2f4;
}

.fg-active-tag {
  @include fg-tag();

  color: white;
  background-color: color.adjust(#f0f2f4, $lightness: -30%);
}

.fg-primary-tag {
  @include fg-tag();

  color: variables.$color-primary;
  background-color: rgba(variables.$color-primary, 0.1);
}

.fg-warning-tag {
  @include fg-tag();

  color: #b08639;
  background-color: #fbf0dc;
}

.fg-success-tag {
  @include fg-tag();

  color: #48a96f;
  background-color: #ebf9f1;
}
