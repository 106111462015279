@use '../../styles/variables';
@use '../../styles/mixins';

.title {
  @include mixins.main-heading;

  margin-bottom: 10px;
}

.logo {
  font-size: 1.2em;
}

.empty-content-message {
  color: variables.$color-text-light;
}
