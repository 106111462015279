@use '../variables';

.fg-chart-wrap {
  position: relative;
  display: flex;
  align-items: center;
  font-family: inherit;

  > div {
    width: 100%;
    height: 100%;
  }
}

.highcharts-container {
  * {
    font-family: inherit !important;
  }
}

.line-chart-widget,
.sov-percentage-chart {
  g.highcharts-legend-item {
    display: none;
  }
}

.indicator {
  // Small dot besides a name, appears in tooltips and legends
  display: block;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.highcharts-legend-item > span > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2em;
  padding: 4px 8px;
  border: 1px solid transparent;
  opacity: 0.5;
  transition: all 200ms ease-in-out;

  &.active {
    border: 1px solid rgb(218, 218, 218);
    opacity: 1;
  }

  &:hover {
    opacity: 0.75;
  }

  .label {
    display: block;
    color: black;
    font-size: 12px;
    font-weight: 600;
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.pie-legend-item {
    justify-content: flex-start;
    border: none;
    width: 290px;

    @media (width <= 1550px) {
      width: 250px;
    }

    @media (width <= 1360px) {
      width: 220px;
    }

    .percentage {
      flex-shrink: 0;
    }
  }
}

.highcharts-axis-title {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.at-a-glance-chart .highcharts-axis-title {
  font-size: 13px;
}

.highcharts-title tspan {
  opacity: 0;
  height: 50px;
}

$tooltip-padding: 16px;

.highcharts-tooltip {
  > span {
    overflow: hidden;
    padding: $tooltip-padding;
    box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border: 1px solid #e2e2e9;
    border-radius: 5px;
  }

  h3,
  th {
    padding: 0 1px;
    text-decoration-line: underline;
    font-size: 12px;
    font-weight: 600;
    color: #777;
  }

  h3 {
    display: inline-block;
    margin-bottom: 10px;
  }

  th {
    padding-bottom: 10px;
    padding-left: 8px;
  }

  ul {
    padding: 14px 0 10px;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #dedee5;

    li {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .events {
    padding: 0 $tooltip-padding;
    background: #fcfcfc;
    margin: 0 (-$tooltip-padding) (-$tooltip-padding); // Negative offset so events section can have a background-color
  }

  .tooltip-data {
    border-bottom: 1px solid #dedee5;
    padding: $tooltip-padding;
    margin: (-$tooltip-padding) (-$tooltip-padding) 0; // Negative offset
  }

  .points {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
    }

    .point-label {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: variables.$color-text-base;

      svg path {
        stroke-width: 0;
      }
    }

    .point-value {
      font-weight: 600;
      font-size: 13px;
      line-height: 13px;
      padding-left: 8px;
      color: #000;
      text-align: right;
      flex: 1;
    }


    th,
    td.point-value {
      text-align: right;
    }

    th.prior {
      color: #949494;
    }

    td.prior {
      color: #777;
    }

    .point-value-pct {
      width: 60px;
      font-size: 13px;
      font-weight: 600;
      text-align: right;
      color: variables.$color-text-light;
    }
  }

  .event-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #888;
    margin-left: 7px;
    max-width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .add-event-btn {
    width: 100%;

    button {
      background: none;
      border: none;
      padding: 7px 0;
      margin: 0;
      color: #9b9b9b;
      font-size: 12px;
      font-weight: variables.$font-weight-semi-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: variables.$color-primary-hover;
      }

      span.plus-icon {
        font-size: 16px;
        margin-right: 9px;
      }
    }
  }
}

.highcharts-credits {
  display: none;
}

.data-label {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;

  .label-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    &.value {
      gap: 3px;
    }
  }

  .data-label-dot {
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .label-name {
    display: inline-block;
    color: #111;
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label-value,
  .percentage-value {
    display: inline-block;
    color: #555;
    font-weight: 500;
  }

  .percentage-value {
    color: #888;
  }
}
