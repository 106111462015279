@use '../../styles/variables';

:global {
  .ant-popover.info-row-popover {
    .ant-popover-inner {
      background-color: #31343b;
    }

    .ant-popover-arrow {
      color: var(--antd-arrow-background-color);
      --antd-arrow-background-color: #31343b;
    }
  }
}

.info-row {
  font-weight: 500;
  font-size: variables.$font-size-smaller;
  color: rgba(109, 112, 119, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .prefix {
    margin-right: 5px;
  }

  .title {
    font-weight: 600;
    font-size: 12.6px;
    margin-right: 6px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow-y: hidden;
  }

  .scrollable-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 8px;

    .shaded & {
      gap: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .strikethrough {
      text-decoration: line-through;
    }

    .filter-box {
      display: flex;
      border: 1px solid variables.$color-border-main;
      border-radius: 4px;
      line-height: 1;
      height: 30px;

      &:empty {
        display: none;
      }
    }

    .item-box {
      display: flex;
      padding: 0 14px;
    }

    .filter-box-divider {
      height: 16px;
      background-color: variables.$color-border-main;
    }

    .filter-item {
      display: flex;
      align-items: center;
      padding-top: 1px; // Fixes vertical alignment issue
    }

    .edit-button {
      height: 28px;
      padding: 0 9px 0 7px;
      border-radius: 0;
      background-color: rgba(variables.$color-primary, 0.05);
      display: flex;
      align-items: center;

      span {
        margin: 0;
        font-weight: 600;
        font-size: variables.$font-size-smaller;

        &:global(.ant-btn-icon) {
          margin-right: 4px;
        }

        &[role='img'] {
          font-size: 14px;
        }
      }

      &:hover,
      &:active {
        background-color: rgba(variables.$color-primary, 0.1);
      }
    }

    .edit-button-divider {
      height: 30px;
      margin: 0;
      border-color: variables.$color-border-main;
    }
  }

  .filter-fade {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 75px;
    height: 100%;

    &.left-fade {
      left: 0;
      background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
    }

    &.right-fade {
      right: 0;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
    }
  }

  .scroll-arrow {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 3px;
    cursor: pointer;

    &.left-scroll-arrow {
      left: 0;
    }

    &.right-scroll-arrow {
      right: 0;
    }

    &:hover svg path {
      stroke: variables.$color-primary;
    }
  }

  .hint-container {
    margin-left: 8px;
    cursor: pointer;

    .info-icon {
      font-size: 12px; // sets icon size for antd icons (otherwise size can be distorted)
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.shaded {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
}

.popover-text {
  color: #fff;
}
