@use 'sass:color';
// ———————————————————— Colors ———————————————————— //

// Primary
$color-primary: #5561f6;
$color-primary-hover: color.adjust($color-primary, $lightness: -11%);
$color-primary-lightened: color.adjust($color-primary, $lightness: 28%);

// Text Colors
$color-text-main-heading: rgba(0, 0, 0, 0.95);
$color-text-primary: rgba(0, 0, 0, 0.85);
$color-text-base: rgba(0, 0, 0, 0.75);
$color-text-muted: rgba(0, 0, 0, 0.65);
$color-text-light: rgba(0, 0, 0, 0.5);
$color-text-lighter: rgba(0, 0, 0, 0.4);
$color-text-extra-light: rgba(0, 0, 0, 0.3);
$color-text-disabled: color.adjust(
  color.adjust(rgba($color-primary, 0.95), $saturation: -50%),
  $lightness: 12%
);

// Background Colors:
$color-background-main: #fafbfc;
$color-background-tabs: $color-background-main;
$color-background-sidebar: #1e3050;

// Border Colors
$color-border-main: #d9d9d9;
$color-border-secondary: rgba(5, 5, 5, 0.1);
$color-border-container: rgba(0, 0, 0, 0.12);

// Table
$color-table-row-border: #ededed;
$color-table-head-bg: #f7f8f9;
$color-table-head-bg-hover: #edeeef;
$color-table-total-row-bg: #fbfbfc;
$color-table-text-bg: #f4f5f7;

// Deltas
$color-delta-red: #dc4949;
$color-delta-green: #068467;

// Resource Header
$color-details-field-text: #787c81;
$color-details-tag-bg: #f3f5f7;
$color-details-icon: #d6d9dc;

// Confidence Scores
$color-confidence-uncertain: #8995a3; // This should be removed eventually

// Rulebooks
$change-set-manually-changes-proposed: #956c22;
$change-set-completed-color: #38aa73;
$change-set-scheduled-changes-proposed: #2988bd;

// Other
$link-color: $color-primary;
$gray-background-color: #ccc;
$white-background-color: #fff;
$card-border-color: #e8e8e8;
$danger-color: #f41e28;
$error-color: rgba(227, 89, 86, 1);
$error-backcolor: rgba(227, 89, 86, 0.2);
$success-color: #52c41a;
$success-backcolor: rgba(82, 196, 26, 0.2);
$warning-color: #faad14;
$warning-backcolor: rgba(250, 173, 20, 0.2);
$ghost-white: rgba(255, 255, 255, 0.2);
$popover-mask-color: rgba(255, 255, 255, 0.9);
$side-menu-item-color: rgba(255, 255, 255, 0.5);
$action-button-color-light: #9cc681;
$action-button-color-medium: #8ab46f;
$action-button-color-dark: #68914d;

// ———————————————————— Layout / Sizing ———————————————————— //

$row-padding: 15px;
$card-padding: 16px 24px;
$filter-panel-padding: 16px 24px;
$content-horiz-padding: 20px;

$btn-right-margin: 10px;
$icon-horizontal-margin: 5px;

// Border Radius
$border-radius-card: 8px;
$border-radius-surface: 8px;
$border-radius-main: 6px;

$table-horiz-padding: 20px;
$table-header-vertical-padding: 12px;

$sidebar-width: 208px;
$sidebar-collapsed-width: 71px;

$dashboard-pdf-content-width: 1063px;

$rule-side-gutters: 24px;
$rule-inner-gutters: 20px;

// Filters Variables
$filters-default-height: 28px;
$filters-tall-height: 32px;
$filters-font-size: 13px;
$filters-font-weight: 500;

// ———————————————————— Typography ———————————————————— //

// Font sizes
$font-size-xxlarge: 28px;
$font-size-xlarge: 20px;
$font-size-large: 18px;
$font-size-medium: 14px;
$font-size-smaller: 12.6px;
$font-size-smallest: 11px;

// Font weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 700;

// ———————————————————— Shadows ———————————————————— //

$box-shadow-xl: 0 8px 8px -4px rgba(0, 0, 0, 0.03), 0 16px 24px -4px rgba(0, 0, 0, 0.08);
$box-shadow-inner-xs: 0 1px 1px 0 rgba(0, 0, 0, 0.02) inset;

/**
  ================================================================================
  EXPORTS
  ================================================================================
*/

// stylelint-disable selector-pseudo-class-no-unknown, property-no-unknown
:export {
  // ———————————————————— Colors ———————————————————— //

  // Primary
  colorPrimary: $color-primary;
  colorPrimaryHover: $color-primary-hover;
  colorPrimaryLightened: $color-primary-lightened;

  // Text Colors
  colorTextMainHeading: $color-text-main-heading;
  colorTextPrimary: $color-text-primary;
  colorTextBase: $color-text-base;
  colorTextMuted: $color-text-muted;
  colorTextLight: $color-text-light;
  colorTextLighter: $color-text-lighter;
  colorTextExtraLight: $color-text-extra-light;
  colorTextDisabled: $color-text-disabled;

  // Background Colors
  colorBackgroundMain: $color-background-main;
  colorBackgroundTabs: $color-background-tabs;
  colorBackgroundSidebar: $color-background-sidebar;

  // Border Colors
  colorBorderMain: $color-border-main;
  colorBorderSecondary: $color-border-secondary;
  colorBorderContainer: $color-border-container;

  // Table
  colorTableRowBorder: $color-table-row-border;
  colorTableHeadBg: $color-table-head-bg;
  colorTableHeadBgHover: $color-table-head-bg-hover;
  colorTableTotalRowBg: $color-table-total-row-bg;
  colorTableTextBg: $color-table-text-bg;

  // Deltas
  colorDeltaRed: $color-delta-red;
  colorDeltaGreen: $color-delta-green;

  // Resource Header
  colorDetailsFieldText: $color-details-field-text;
  colorDetailsTagBg: $color-details-tag-bg;
  colorDetailsIcon: $color-details-icon;

  // Rulebooks
  changeSetManuallyChangesProposed: $change-set-manually-changes-proposed;
  changeSetCompletedColor: $change-set-completed-color;
  changeSetScheduledChangesProposed: $change-set-scheduled-changes-proposed;

  // Other
  linkColor: $link-color;
  grayBackgroundColor: $gray-background-color;
  whiteBackgroundColor: $white-background-color;
  cardBorderColor: $card-border-color;
  dangerColor: $danger-color;
  errorColor: $error-color;
  errorBackcolor: $error-backcolor;
  successColor: $success-color;
  successBackcolor: $success-backcolor;
  warningColor: $warning-color;
  warningBackcolor: $warning-backcolor;
  ghostWhite: $ghost-white;
  popoverMaskColor: $popover-mask-color;
  sideMenuItemColor: $side-menu-item-color;
  actionButtonColorLight: $action-button-color-light;
  actionButtonColorMedium: $action-button-color-medium;
  actionButtonColorDark: $action-button-color-dark;

  // ———————————————————— Layout / Sizing ———————————————————— //

  rowPadding: $row-padding;
  cardPadding: $card-padding;
  filterPanelPadding: $filter-panel-padding;
  contentHorizPadding: $content-horiz-padding;
  btnRightMargin: $btn-right-margin;
  iconHorizontalMargin: $icon-horizontal-margin;
  borderRadiusCard: $border-radius-card;
  borderRadiusSurface: $border-radius-surface;
  borderRadiusMain: $border-radius-main;
  tableHorizPadding: $table-horiz-padding;
  tableHeaderVerticalPadding: $table-header-vertical-padding;
  sidebarWidth: $sidebar-width;
  sidebarCollapsedWidth: $sidebar-collapsed-width;
  dashboardPdfContentWidth: $dashboard-pdf-content-width;
  ruleSideGutters: $rule-side-gutters;
  ruleInnerGutters: $rule-inner-gutters;

  // Filters Variables
filtersDefaultHeight: $filters-default-height;
filtersTallHeight: $filters-tall-height;

  // ———————————————————— Typography ———————————————————— //

  // Font sizes
  fontSizeXxLarge: $font-size-xxlarge;
  fontSizeXLarge: $font-size-xlarge;
  fontSizeLarge: $font-size-large;
  fontSizeMedium: $font-size-medium;
  fontSizeSmaller: $font-size-smaller;
  fontSizeSmallest: $font-size-smallest;

  // Font weights
  fontWeightExtraLight: $font-weight-extra-light;
  fontWeightLight: $font-weight-light;
  fontWeightNormal: $font-weight-normal;
  fontWeightSemiBold: $font-weight-semi-bold;
  fontWeightBold: $font-weight-bold;

  // ———————————————————— Shadows ———————————————————— //
  boxShadowXl: $box-shadow-xl;
  boxShadowInnerXs: $box-shadow-inner-xs;
}
