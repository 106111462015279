.ant-form-item {
  margin-bottom: 20px;
}

.ant-input-lg,
.ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 14px;
}

.ant-input-number > .ant-input-number-handler-wrap {
  opacity: 1;
}
