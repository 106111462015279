@use "sass:color";
@use '../../styles/variables';

.button {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.link {
  display: inline;
  color: variables.$color-primary;
  transition: color 0.3s;

  &:hover {
    color: color.adjust(variables.$color-primary, $lightness: 5%);
  }
}

.link.disabled {
  color: rgba(0, 0, 0, 0.25);
  transition: unset;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}
