@use '../../../../styles/variables';

.delta-tooltip {
  :global {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      color: variables.$color-text-primary;
      background: #fff;
      padding: 14px 17px;

      .delta-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      .delta-value {
        margin-right: 0.5rem;

        & + .delta-value {
          padding-left: 0.5rem;
          border-left: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.toggle-icon {
  display: inline-block;
  cursor: pointer;
  margin-top: 4px;
  fill: #87898d;
  opacity: 0.7;
  transition: opacity 0.2;

  &.collapsed {
    transform: rotate(180deg);
  }

  &:hover {
    opacity: 1;
  }
}
