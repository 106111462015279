.stack {
  display: flex;
  flex-direction: column;

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row {
    flex-direction: row;
  }

  &.align-items-start {
    align-items: flex-start;
  }

  &.align-items-end {
    align-items: flex-end;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-stretch {
    align-items: stretch;
  }

  &.justify-content-start {
    justify-content: flex-start;
  }

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-between {
    justify-content: space-between;
  }

  &.justify-content-around {
    justify-content: space-around;
  }
}
