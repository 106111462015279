.ant-badge {
  .ant-badge-count {
    margin-left: 4px;
    border-radius: 3px;
    min-width: 17px;
    height: 20px;
    font-size: 11.75px;
    font-weight: 500;
    letter-spacing: -0.03em;
    background-color: #ff7875;
    transition: background-color 0.3s;
  }

  .ant-badge-dot {
    top: 4px;
    right: 4px;
    background-color: #ff7875;
  }
}
