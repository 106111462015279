@use '../../styles/variables';

.popover-wrapper {
  width: 472px;
  padding: 6px 8px;

}

.popover-title {
  font-size: 16px;
  margin-bottom: 10px;
}

.popover-divider {
  margin: 12px 0;
}

.keyword-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.ant-table-row):hover {
  .keyword-link {
    padding-right: 15px;
  }
}


.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
