.field {
  & > *:not(button) {
    width: 100%;
  }
}

.bulk-edit-row {
  background: #fafafa;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
}

.match-type-column {
  width: 20%;
}

.ad-group-column {
  width: 20%;
}

.remove-column {
  width: 6%;
}

.header-label {
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 8px;
}

.empty-table-text {
  text-align: center;
}
