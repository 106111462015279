.fg-flag-container {
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  gap: 4px;
}

.fg-flag-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 20px;
  max-width: 20px;

  &::after {
    border: 1px solid rgba(0, 0, 0, 0.18);
    height: 15px;
    width: 20px;
    display: block;
    content: "";
    position: absolute;
    top: calc(50% - 7.5px);
  }

  > svg {
    width: 100%;
    height: auto;
  }
}
