@use '../../../styles/variables';

.modal-wrap {
  overflow: hidden;
}

.change-columns {
  background-color: rgb(250, 250, 250);
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 15px 34px;
  margin: 0 -24px 16px;
  display: flex;
  align-items: flex-start;

  span {
    font-weight: 500;
  }

  .period-delta-config {
    border-top: 1px solid #dedfe1;
    padding-top: 15px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }

  .period-delta-config-type {
    display: flex;
    align-items: center;
  }

  .period-delta-date-picker {
    margin-top: 10px;
  }
}

.text-disabled {
  color: variables.$color-text-extra-light;
}
