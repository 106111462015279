@use '../../styles/variables';

.popover-wrapper {
  :global(.ant-popover) {
    z-index: 1000;
  }
}

:global {
  .ant-popover {
    .ant-popover-inner {
      .ant-tag {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 22px;

        .anticon.anticon-close {
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

// Metric popover offset on draggable lists

:global([data-rbd-droppable-id="itemsList"]) {
  .popover-wrapper > div {
    width: calc(100% + 70px);
    padding-left: 36px;
    position: relative;
    left: -36px;
  }
}
