@use '../../styles/variables';
@use '../../styles/mixins';

.wrapper-position {
  // higher than components with full-content @mixin so header is always clickable
  z-index: 1000;
}

.elevated {
  @include mixins.box-shadow-surface-xs;
}

.color-white {
  background-color: white;
}

.divider {
  height: 45px;
  width: 0;
  margin-right: 40px;
  margin-left: 40px;
}

.header-nav {
  position: relative;
  top: -5px;
  right: -9px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  li {
    a:hover {
      text-decoration: none;
    }

    a,
    :global(.ant-btn) {
      padding: 0 8px;
      font-weight: 400;
      transition: all 0.2s ease;
    }

    :global(.ant-btn) {

      :global(.anticon) {
        font-size: 16px;
        position: relative;
      }

      &:hover,
      &:focus {
        :global(.anticon) {
          color: variables.$color-primary-hover !important;
        }
      }
    }

    :global(.ant-btn:disabled) {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}

.filters-wrap {
  position: relative;
  padding-bottom: 8px;

  .filters-overlay {
    @include mixins.full-content;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    font-weight: 500;
  }
}
