// Local Variables
$warning-banner-border-color: #ffcfce;
$impersonation-mode-icon-color: #ff7a77;
$impersonation-mode-border-color: #ffcfce;
$warning-icon-color: #bf8900;
$warning-border-color: #f4dc8e;

.warning-icon {
  margin-right: 8px;
  color: $warning-icon-color;
  font-size: 14px;
}

.info-alert {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 24px !important;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
}

@mixin warning-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  z-index: 1000; // so it's still clickable when viewing a locked org
  border-bottom: 1px solid $warning-border-color !important;
  border-top: 1px solid $warning-border-color !important;

  :global(.ant-alert-close-icon) {
    top: unset !important;
    padding-right: 8px;
  }
}

.warning-banner {
  @include warning-banner;

  border-bottom: 1px solid $warning-border-color;
  padding: 5px !important;
}

.impersonation-mode-banner {
  @include warning-banner;

  padding: 5px !important;
  border-bottom: 1px solid $impersonation-mode-border-color;
  border-top: 1px solid $impersonation-mode-border-color;

  :global(.anticon) {
    font-size: 14px;
    margin-right: 7px;
    color: $impersonation-mode-icon-color;
  }
}
