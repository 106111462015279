@use '../../../styles/variables';

.warning-icon {
  margin-left: 5px;
  margin-right: 5px !important;
  font-size: 14px;
}

.danger {
  color: variables.$danger-color;
}

.warn {
  color: #bf8900;
}
