.ant-select-item-empty {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  min-height: 32px;
  padding: 5px 12px;
  position: relative;
  color: rgba(0, 0, 0, 0.25);
}
