@use '../../../styles/variables';

.cell-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  div:nth-child(1) {
    margin-right: 5px;
  }
}

.popover-table-container {
  display: flex;
  flex-direction: column;
  width: 320px;

  .header {
    text-align: center;
    font-size: variables.$font-size-large;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 6px;
  }

  .table {
    text-align: center;

    .key {
      font-weight: variables.$font-weight-bold;
    }

    .notes {
      text-align: left;
      font-size: variables.$font-size-smaller;
      padding-left: 15px;
      font-style: italic;
      margin-top: 10px;
    }

    :global(.ant-table-small) {
      border: 0;
      border-radius: 0;
    }
  }
}

.popover-text-container {
  width: 250px;
}
