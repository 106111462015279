@use '../../styles/variables';

.tag-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px !important;
  width: 16px !important;
  min-width: 16px !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  margin-left: 3px;
  line-height: 1.1;

  & span {
    height: 12px;
    font-size: 12px !important;
    line-height: 1.1 !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;

    &.on-click-hover {
      color: variables.$color-primary !important;
    }
  }
}

.pasted-tag {
  display: inline-flex;
  align-items: center;
  margin-right: 0;
  border-radius: 2em !important;
  cursor: pointer;
  transition: all 50ms ease-in-out;
  height: 23px;
  border: none;
  color: variables.$color-text-muted;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none !important;
  font-size: 13px;
  padding: 0 7px 0 9px !important;
  font-weight: 500 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.1;

  &:hover {
    box-shadow: none !important;
    opacity: 1;

    &.on-click-hover {
      color: variables.$color-primary !important;
      background-color: #ebecf5;

      & .tag-close-btn svg {
        color: variables.$color-primary;
      }
    }
  }

  & svg {
    color: rgba(0, 0, 0, 0.4);
  }
}

.pasted-tag-error {
  background-color: rgba(219, 106, 104, 0.11);
  color: variables.$error-color;

  & .tag-close-btn {
    & svg {
      color: variables.$error-color;
    }

    &:hover {
      background-color: #dfc1c1 !important;
    }
  }
}
