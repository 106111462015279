.phone-input-display {
  * {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
  }

  input {
    width: 124px;
  }
}
