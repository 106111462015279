@use '../../../../styles/variables';

.item-search-input {
  border: 1px solid #d4d4d4;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  button,
  :global(.ant-input-group-addon) {
    background: none !important;
  }

  :global(.ant-input-affix-wrapper) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: none !important;

    input {
      padding-top: 1px !important;
    }
  }

  :global(.ant-input-affix-wrapper-focused) {
    box-shadow: none !important;
  }
}

.item-search-body {
  min-height: 175px;
  max-height: 175px;
  overflow: auto;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0 14px 8px 14px;
  box-shadow: 0 3px 8px 0 #0000001f;

  &.initial-state {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f6f6;
    color: variables.$color-text-light;
    box-shadow: none;
  }
}

.results-empty-state {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: variables.$color-text-light;
}

.item-row-header {
  margin: 0 !important;
  padding: 10px 0 8px 0;
  font-weight: 600;
  background-color: white;
  border-bottom: 1px solid #00000040;
  color: rgba(0, 0, 0, 0.75);
}

.item-row-result {
  margin: 0 !important;
  border-bottom: 1px solid #00000040;

  &:last-child {
    border-bottom: none !important;
  }

  button {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0;
    border-radius: 0;
    border: none !important;
    height: 28px;

    &:focus {
      background: none;
    }

    &:hover {
      background-color: #f0f3f4;
    }
  }
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  width: calc(100%);
  text-overflow: ellipsis;
}
