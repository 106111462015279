@use '../../../styles/variables';

.base {
  display: none;
  position: fixed;
  top: 0;
  left: variables.$sidebar-width;
  right: 0;
  background: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 999;
  transition: left 0.2s;

  &.sidebar-collapsed {
    left: variables.$sidebar-collapsed-width;
  }

  &.visible {
    display: block;
  }
}
