@use '../../../../styles/variables';

.editable-cell {
  outline: none !important;
  border: 1px solid transparent !important;
  background-color: transparent !important;
  font-size: variables.$font-size-smaller !important;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    border: 1px solid rgba(#000, 0.2) !important;
    background-color: transparent !important;
  }
}

:global(tr.ant-table-row) {
  &:hover,
  &:active,
  &:focus {
    .editable-cell {
      outline: none !important;
      border: 1px solid rgba(#000, 0.2) !important;
      background-color: transparent !important;
    }
  }
}
