@use '../../../styles/variables';

.item {
  padding: 10px;
  display: flex;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
  justify-content: space-between;
}

.item:last-of-type {
  border-bottom: 1px solid transparent;
}
