// Modal.confirm
.ant-modal-confirm {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 14px;
    }

    .anticon-question-circle {
      display: none;
    }

    & > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
      margin-left: 0;
    }

    .ant-modal-confirm-content {
      margin-top: 16px;
    }
  }

  .ant-modal-confirm-btns {
    float: none;
    margin-top: 42px;

    .ant-btn-primary {
      float: left;
      margin: 0 8px 0 0;
    }
  }
}

.ant-modal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      padding: 16px 24px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 0;
    }

    .ant-modal-body {
      padding: 24px 24px;
    }

    .ant-modal-footer {
      border-top: 1px solid #f0f0f0;
      padding: 10px 16px;
    }
  }
}
