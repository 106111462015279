@use '../variables';

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 1.3;

  .ant-collapse-arrow {
    top: 15px;
  }

  &:hover {
    color: variables.$color-primary;
    background-color: #f4f4f9;
  }

  > .ant-collapse-expand-icon {
    align-self: center;
  }
}
