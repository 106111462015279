@use '../../../styles/variables';

.ad-item-modal {
  :global(.ant-modal-body) {
    padding-top: 6px !important;
  }
}

.field {
  & > *:not(button) {
    width: 100%;
  }
}

.ad-item-table-container {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 0 12px 8px;
  max-height: 312px;
  overflow: auto;

  .sticky {
    padding-top: 8px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.bulk-edit-row {
  background: #fafafa;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bebfbe;
  border-top: 1px solid #bebfbe;
}

.ad-item-row-header {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 8px;
}

.ad-item-row {
  border-bottom: 1px solid #e5e5e5;

  &:nth-last-child(2) {
    border-bottom: none;
  }
}

.remove-column {
  width: 6%;
}

.empty-table-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  background-color: #f5f6f6;
  color: variables.$color-text-light;
  border-radius: 4px;
}

.text-field {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-width: 0;

  .name-field {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
  }
}

.add-items-tabs {
  overflow: visible;

  :global(.ant-tabs-nav) {
    padding: 0 !important;
    box-shadow: none !important;
  }

  :global(.ant-tabs-content-holder) {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}
