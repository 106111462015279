@use '../mixins';
@use '../variables';

.ant-popover {
  @include mixins.box-shadow-surface-large;

  border-radius: variables.$border-radius-surface;
  border: 1px solid variables.$color-border-main;
  overflow: hidden;
}

.ant-popover-arrow {
  width: 16px;
  height: 16px;
}
