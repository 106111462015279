@use "sass:color";
@use '../variables';

.ant-table {

  th,
  td {
    line-height: 1.3;
  }

  .ant-input-number {
    margin: 5px 0;
  }

  .ant-form-item-control {
    line-height: initial;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    z-index: 5;
    position: relative;
    bottom: 1px; // covers the last table bottom border instead of making a 2px line
    width: 100%;
    background-color: variables.$color-table-row-border;
  }

  &.ant-table-empty {
    &::after {
      content: '';
      display: none;
    }
  }
}

.ant-pagination {
  align-items: center;
  margin: 12px 0 !important;

  .ant-pagination-item {
    border: 1px solid variables.$color-border-main;

    &.ant-pagination-item-active {
      border-color: variables.$color-primary;
      background-color: color.adjust(variables.$color-primary, $lightness: 34%);
    }
  }
}


.ant-table-container {
  .ant-btn-sm {
    padding: 0 4px;
    height: 22px;
    line-height: 22px;
  }
}

.ant-table-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: variables.$table-header-vertical-padding variables.$table-horiz-padding !important;
  border-bottom: 1px solid variables.$color-border-container;
}

.ant-table-thead {

  .ant-table-cell {

    .ant-table-column-sorter {
      transition: opacity 0.3s;
      opacity: 0.3;
    }

    &.ant-table-column-sort .ant-table-column-sorter {
      opacity: 1 !important;
    }
  }

  &:hover .ant-table-cell {

    .ant-table-column-sorter {
      opacity: 0.7;
    }
  }
}

.ant-table-cell {

  &:first-child {
    padding-left: variables.$table-horiz-padding !important;
  }

  &.ant-table-selection-column {
    padding-right: 2px !important;
  }

  .ant-checkbox-wrapper {
    align-items: end;
    padding-top: 1px;
  }
}

// Fix the Antd Issue where the fixed-column shadow doesn't show up like it should
.ant-table-cell-fix-right-first::after {
  border-right: 1px solid variables.$color-table-row-border;
  box-shadow: inset -10px 0 6px -9px rgba(2, 2, 2, 0.04);
}

// This is to unset the height in grid view to match the style in normal pages.
// (react-grid-layout will set height to 100%.)

// This removes the ugly extra vertical box shadow(which shows up when table is horizontal scrollable)
// when widget height is much larger than the height sum of all table rows.
.react-grid-item .ant-card .ant-card-body .ant-table-container {
  height: unset !important;
}
