.recipients-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid #efefef;
    border-radius: 3px;
    padding: 5px;
    color: rgba(0, 0, 0, 0.5);
  }

  .recipients-list--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 4px;
    padding-bottom: 4px;
    line-height: 30px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @mixin recipient-email {
    max-width: 100%;
  }

  .recipient-email {
    @include recipient-email;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .recipient-email--wrapper {
    display: flex;
    align-items: center;
  }
