@use '../../styles/variables';

.ant-layout-sider {
  background: #1e3050;

  .ant-menu-item[role='menuitem'] {
    &.ant-menu-item-selected {
      &.mps-theme {
        background-color: #12679a;
      }
    }
  }

  #divider-container {
    margin: 12px 0 !important;
    padding: 0 !important;
    height: 1.1px !important;
  }

  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    background-color: transparent;
  }

  // add additional organization-specific theme styling here
  &.mps-theme {
    .ant-menu-item[role='menuitem'] {
      &.ant-menu-item-selected {
        background-color: #12679a;
      }
    }
  }

  &.ant-layout-sider-collapsed li {
    text-align: center;

    span[role='img'] {
      line-height: 38px;
    }
  }
}

.ant-dropdown-menu-submenu-title {
  padding-right: 30px;
}
