@use '../../../../../styles/variables';

.pending-invites-fieldset {
  margin-top: 20px;
}

.pending-invites-item {
  line-height: 1em;
}

.pending-invites-item-label {
  opacity: 0.5;
}
