.field {
    .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global(.ant-table-row):hover {
    .field {
        .text  {
      padding-right: 15px;
    }
  }
}
