.ant-spin {
    pointer-events: none;
    user-select: none;
}

.ant-spin-nested-loading {
    height: 100%;
  }

.ant-spin-container {
    height: 100%;
}
