@use '../../../styles/variables';

.dashboard-table-header {
  th.title-with-tooltip {
    padding: 0 8px 0 0 !important;

    :global(.ant-table-column-sorters) {
      height: 100%;
    }

    .dashboard-table-header-title {
      height: 100%;
      padding: 8px 0 8px 8px;
    }

    &:first-child {
      .dashboard-table-header-title {
        height: 100%;
        padding: 8px 0 8px 12px;
      }
    }

    transition: background-color 1s;

    &:hover {
      background-color: variables.$color-table-head-bg-hover !important;
    }
  }
}
