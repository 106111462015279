@use 'sass:color';
@use '../variables';

.fg-color-danger {
  color: variables.$danger-color;

  &:hover,
  &:active,
  &:focus {
    color: variables.$danger-color;
  }
}

.fg-color-warning {
  color: variables.$warning-color;

  &:hover,
  &:active,
  &:focus {
    color: variables.$warning-color;
  }
}

.fg-text-primary {
  color: variables.$color-text-primary;
}

.fg-text-muted {
  color: variables.$color-text-muted;
}

.fg-text-light {
  color: variables.$color-text-light;
}

.fg-text-extra-light {
  color: variables.$color-text-extra-light;
}

*::selection {
  color: color.adjust(
    color.adjust(variables.$color-primary, $lightness: -48%),
    $saturation: -60%
  );
  background-color: rgba(variables.$color-primary, 0.18);
}

.fg-primary-color {
  color: variables.$color-primary;
}
