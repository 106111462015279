@use '../../../styles/variables';

.cell {
  display: flex;
  align-items: center;

  .percentage {
    font-weight: 600;
    font-size: 11px;
    display: block; // Vertically centers the text
  }

  .over-budget {
    font-size: 11px;
  }

  .status-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-weight: 500;
    font-size: 12.6px;
    color: variables.$color-text-muted;

    span {
      line-height: 1.3;
    }
  }
}

.icon {
  margin-left: 6px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}

.tooltip {
  width: 312px;
  font-weight: 500;
  font-size: 12.6px;
  line-height: 1.3;
  padding: 8px 4px;
  color: variables.$color-text-base;

  .progress-bar {
    margin: 12px 0 2px;
    line-height: 3px;
  }

  .spend-info {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 2px;

    :first-child {
      flex-grow: 1;
    }

    .icon {
      margin-left: 0;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .abc-details {
    display: flex;
    flex-direction: column;

    .divider {
      margin: 14px 0;
      background-color: variables.$color-border-main;
    }

    .icon {
      font-size: 24px;
      margin: 0 10px 0 4px;
      color: rgba(0, 0, 0, 0.65);
    }

    .observation {
      font-style: italic;
      color: variables.$color-text-light;
    }

    .under-budget {
      color: #0ba55f;
    }

    .over-active-budget {
      color: #ad8419;
    }

    .over-base-budget {
      color: #c45500;
    }
  }

  .updated-at {
    margin-top: 14px;
    font-style: italic;
    color: variables.$color-text-light;
    text-align: right;
  }
}
