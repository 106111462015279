.grouped-capabilities--wrapper {
  cursor: pointer;
}

.capability-group-icon--wrapper {
  height: 20px;
  width: 20px;
}

.capability-group-icon {
  position: absolute;
  background: #fff;
  padding: 2px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      left: #{($i - 1) * 15px};
    }
  }
}
