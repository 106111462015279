@use '../../styles/variables';

.container {
  width: variables.$dashboard-pdf-content-width;
  margin: 0 auto;

  * {
    box-shadow: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    pointer-events: none;
  }

  :global(.ant-card-head) {
    border-bottom: 1px solid #d0d0d0;
  }

  header {
    position: relative;
    margin-bottom: 35px;

    .metadata {
      span {
        display: inline-block;
        width: 85px;
        color: #9e9e9e;
      }

      .info-icon {
        width: 20px;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    h1 {
      margin-bottom: 0;
    }

    .org-logo {
      position: absolute;
      top: 0;
      right: 0;
      max-height: 80px;
      max-width: 270px;
    }

    .org-name {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      font-weight: 500;
      padding: 4px 18px;
      background: #eee;
      border-radius: 2px;
    }
  }

  :global(.react-grid-item) {
    border: 1px solid #e5e2ea;
    border-radius: 4px;
    box-shadow: none;
  }

  .tab-section {
    .tab-header {
      display: flex;
      justify-content: space-between;

      &:not(:global(.first-tab-header)) {
        page-break-before: always;
      }

      .date-range {
        display: flex;
        align-items: center;

        .info-icon {
          font-size: 14px;
          margin-left: 5px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .tab-content {
      .widget {
        break-inside: avoid;

        :global(.ant-pagination-item),
        :global(.ant-pagination-prev),
        :global(.ant-pagination-next),
        :global(.ant-pagination-jump-next),
        :global(.ant-pagination-options),
        :global(.ant-table-column-sorter-up.off),
        :global(.ant-table-column-sorter-down.off) {
          display: none;
        }

        :global(.ant-table-column-sorter-down.on) {
          color: inherit;
          padding-top: 2px;
        }

        &.table :global(.ant-pagination) {
          position: relative;
          z-index: 1000;
        }
      }
    }
  }

  footer {
    font-weight: 500;
    color: variables.$color-text-primary;
    margin-top: 20px;

    .ds-icon {
      display: flex;
      flex-direction: column;
      text-align: right;

      .powered {
        margin-bottom: 2px;
        letter-spacing: 0.05em;
        font-size: 9.5px;
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.page-break {
  border-bottom: 1px solid variables.$color-border-main;
  margin-top: 72px;
  margin-bottom: 58px;
}
