@use '../../../styles/variables';

.field {
  & > *:not(button) {
    width: 100%;
  }
}

.keyword-table-container {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 0 12px 8px;
  max-height: 312px;
  overflow: auto;

  .sticky {
    padding-top: 8px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.bulk-edit-row {
  background: #fafafa;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bebfbe;
  border-top: 1px solid #bebfbe;
}

.keyword-row {
  border-bottom: 1px solid #e5e5e5;

  &:nth-last-child(2) {
    border-bottom: none;
  }
}

.remove-column {
  width: 6%;
}

.empty-table-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  background-color: #f5f6f6;
  color: variables.$color-text-light;
  border-radius: 4px;
}

.text-field {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.suggested-keywords-collapse {
  margin-bottom: 20px !important;

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}

.suggested-keywords-wrapper {
  height: 92px;
  overflow: auto;
  padding: 8px;

  &.empty-state {
    height: 56px;
    color: variables.$color-text-light;
  }

  &.no-keywords {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .keyword-tag {
    margin: 5px 2px;
  }
}
