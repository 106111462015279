@use '../../../../styles/variables';

.metric {
  margin-right: 30px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 11.5px;
  font-weight: 600;
  color: variables.$color-text-extra-light;
}

.drag-handle {
  margin-left: auto;
  height: 14px;
  width: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;

  span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: variables.$color-text-muted;
    border-radius: 2px;
  }
}
