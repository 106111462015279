@use '../../styles/variables';

.typeahead {
  display: flex;
  flex-direction: column;

  .menu {
    box-sizing: border-box;
    outline: none;

    .menu-title {
      color: variables.$color-text-extra-light;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 4px 0;
    }

    ul {
      max-height: 225px;
      margin-bottom: 0;
      padding: 4px 0;
      padding-left: 0;
      overflow: auto;
      list-style: none;
      outline: none;
    }
  }

  .selected-options {
    text-align: left;
    margin-bottom: -10px;
  }

  .selected-options-divider {
    margin: 12px 0;
  }

  .tag {
    margin-bottom: 6px;
  }
}

.typeahead-item {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  transition: background 0.3s ease;
  border: 1px solid #fff;
  border-radius: 4px;
  left: 4px;
  width: calc(100% - 8px);
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  min-height: 32px;
  padding: 5px 12px;
  position: relative;
}

.typeahead-item-option-active {
  background-color: #f5f5f5;
}

.typeahead-item-option-selected:not(.typeahead-item-option-disabled) {
  color: #5561f6;
  font-weight: 500;
  background-color: #f0f3ff;
}

.typeahead-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
