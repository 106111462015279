@use '../../../styles/variables';

.widget-type-container {
  cursor: pointer;
  border: 1px solid rgb(214, 214, 217);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgb(238, 238, 238);
  padding: 28px 0;
  transition: all 0.3s;
  color: rgb(142, 143, 152);
  width: 145px;
  text-align: center;
}

.widget-type-container:hover,
.widget-type-container-active {
  background-color: rgb(244, 254, 255);
  border-color: variables.$color-primary;

  .widget-icon {
    fill: variables.$color-primary;
  }

  .widget-title {
    color: variables.$color-primary;
  }
}

.widget-icon {
  fill: rgb(142, 143, 152);
  transition: fill 0.3s;
}

.widget-title {
  text-align: center;
  font-weight: 500;
  transition: color 0.3s;
}
