@use '../../styles/variables';

html,
body {
  margin: 0;
  color: variables.$color-text-primary;
  font-family: proxima-nova, 'Noto Sans', 'Noto Sans JP', sans-serif;
  font-feature-settings: 'tnum';
  background-color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
}

.content-layout {
  overflow-x: auto !important;
  max-width: calc(100% - #{variables.$sidebar-width});
  margin-left: variables.$sidebar-width;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease;

  .content {
    position: relative;
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: transparent;
    padding: 0;
  }
}

.collapsed {
  max-width: calc(100% - #{variables.$sidebar-collapsed-width});
  margin-left: variables.$sidebar-collapsed-width;
}

// Layout Component
:global(.ant-layout) {
  background-color: variables.$color-background-main;
}


// Layout Sider Component
:global(.ant-layout-sider-collapsed) {
  flex: 0 0 variables.$sidebar-collapsed-width !important;
  max-width: variables.$sidebar-collapsed-width !important;
  min-width: variables.$sidebar-collapsed-width !important;
  width: variables.$sidebar-collapsed-width !important;

  :global(.ant-layout-sider-trigger) {
    width: 47px !important;
  }
}

.sider {
  height: 100vh;
  position: fixed !important;
  overflow: hidden;
  left: 0;
}

:global(.ant-layout-sider-trigger) {
  background-color: rgba(#fff, 0.18) !important;
  border-radius: 50px;
  height: 34px !important;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 186px !important;
}

:global(.ant-layout-sider-children) {
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
}
