@use '../../styles/variables';

.error-div {
  padding: 48px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background-color: #fff;

  button {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  p {
    margin-top: 20px;
  }
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 160px;
    margin-right: 8px;
  }
}

.background {
  display: flex;
}
