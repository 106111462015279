$indicator-badge-height: 12px;

.badge {
  height: $indicator-badge-height;
  width: $indicator-badge-height;
  border-radius: 50%;
  background-color: currentColor;
}

.label {
  line-height: $indicator-badge-height;
  font-size: $indicator-badge-height;
  font-weight: 500;
}
