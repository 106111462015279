@use '../../../styles/variables';

.metric-formula-container {
  background: #f4f5f7;
  padding: 10px 14px 10px 14px;
  margin-top: 8px;
  border-radius: 4px;
  font-weight: 500;
  font-family: 'Noto Sans Mono', monospace;
  font-size: 0.95em; // This font renders much larger than Proxima Nova

  .metric-formula {
    .primary-metric {
      color: #0a7de7;
    }

    .equals-sign {
      color: #00000080;
    }

    .operand {
      color: #1ea059;
    }

    .operator {
      color: #7706e7;
    }
  }
}

.tooltip-formula-wrapper {
  margin-bottom: 0;

  & div {
    font-size: 11px;
  }

  :global(.ant-typography) {
    margin-bottom: 0;
  }
}

.group-by-tooltip,
.metric-tooltip,
.field-tooltip {
  :global(.ant-popover-title) {
    min-width: auto !important;
    margin: 0;
  }

  :global(.ant-popover-inner) {
    padding: 0;

    p {
      margin-bottom: 0.7em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.column-tooltip {
  color: variables.$color-text-light;
  margin-right: 0.2em;
  font-size: variables.$font-size-smallest;
  font-weight: 500;
  margin-top: 2px;
}
