@use '../variables';

.ant-divider-inner-text {
  font-size: 0.65em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.6px;
  font-weight: 600;
}

.ant-divider {
  &.ant-divider-with-text-left {
    &::before {
      width: 0%;
    }

    .ant-divider-inner-text {
      padding-left: 0;
    }
  }
}
