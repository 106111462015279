@use '../../../styles/variables';

.actions {
  opacity: 0;
  font-size: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding: 2px 2px;
  transition: all 0.3s;

  .icon-actions {
    display: flex;
    align-items: center;
    gap: 6px;
  }

}

.research-link {
    color: variables.$color-text-light;

    a {
        svg {
          fill: variables.$color-text-light;
        }

        :hover {
            fill: variables.$color-primary;
        }
      }
}

:global(.ant-table-row):hover {
  .actions {
    opacity: 1;
  }
}
