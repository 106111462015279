@use '../../styles/variables';

:global(.ant-tooltip-inner) {
  word-break: normal;
}

.tooltip {
  max-width: 200px;

  span {
    display: block;
    line-height: 1.33;
    margin: 4px 3px;
  }

  .title {
    margin-top: 6px;
    font-weight: variables.$font-weight-semi-bold;
  }

  .description {
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
  }
}
