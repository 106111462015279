@use '../../styles/variables';
@use '../../styles/mixins';

.form-container {
  position: absolute;
  top: 190px;
  width: 480px;
  background-color: #fff;
  border-radius: 4px;

  @include mixins.box-shadow-surface-xs;
}

.form {
  padding: 48px 40px;
  text-align: center;

  button[type='submit'] {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 24px;
    font-size: 14px;
  }

  input,
  button[type='submit'] {
    border-radius: 4px;
  }

  p {
    color: variables.$gray-background-color;
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 20px;

    h1 {
      font-weight: 500;
      font-size: 23px;
      line-height: 28px;
    }

    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .title_darker {
    margin-bottom: 20px;

    h1 {
      font-weight: 500;
      font-size: 23px;
      line-height: 28px;
    }

    p {
      font-size: 14px;
      color: variables.$color-text-muted;
    }
  }

  .input_box {
    width: 200px;
  }

  .disabled_link {
    pointer-events: none;
    color: variables.$color-text-muted;
  }

  .footer {
    background: rgba(239, 241, 247, 0.5);
    margin: 32px -40px -48px;
    padding: 12px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  .sign-up {
    color: variables.$color-text-main-heading;
    font-weight: variables.$font-weight-semi-bold;
  }

  .steps {
    margin: 32px 0;
  }
}

.logo {
  position: absolute;
  top: 105px;
}

.container {
  background-color: #eff1f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

.logout {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.subtext {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 20px;
}

// Mobile Styles
@media (width <= 520px) {
  .container {
    background-color: #fff;
  }

  .form {
    width: 100%;
    top: 86px;
    padding: 32px 32px 48px;
    box-shadow: none;

    .footer {
      background-color: #fff;
      margin-top: 16px;
    }
  }

  .logo {
    position: absolute;
    top: 30px;
  }
}

.languageSelector {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #909090;
}
