@use '../../../styles/variables';

.display {
  display: flex;
  text-align: left;
  width: 100%;

  .id {
    min-width: 44px;
    text-align: right;
    padding-right: 5px;
  }

  .action {
    color: variables.$color-text-muted;
  }
}
