@use '../../../../styles/variables';

.label {
  color: variables.$color-text-light;
  font-size: variables.$font-size-smaller;
  font-weight: variables.$font-weight-bold;
  margin-top: 10px;
  margin-bottom: 8px;
}

.value {
  color: variables.$color-text-primary;
  font-size: variables.$font-size-medium;
  font-weight: variables.$font-weight-semi-bold;
  margin-bottom: 12px;
}

.term {
  font-size: variables.$font-size-xlarge;
  font-weight: variables.$font-weight-bold;
  margin-bottom: 16px;
}
