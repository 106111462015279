@use '../../../styles/variables';

.danger {
  color: variables.$danger-color;
}

.success {
  color: variables.$success-color;
}

.warning {
  color: variables.$warning-color;
}
