@use "sass:color";
@use 'variables';

// Shadows
@mixin box-shadow-surface-xs {
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.1),
  0 2px 1px -1px rgba(0, 0, 0, 0.02),
  0 2px 2px -2px rgba(0, 0, 0, 0.04);
}

@mixin box-shadow-surface-large {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

@mixin full-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

@mixin custom-scrollbar {
  $scrollbar-thickness: 8px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $scrollbar-thickness;
    height: $scrollbar-thickness;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-thickness;
    background-color: rgb(233, 235, 238);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  }
}

@mixin main-heading {
  font-weight: 400;
  font-size: 21px;
  color: variables.$color-text-main-heading;
  margin: 0;
}

@mixin secondary-heading {
  font-weight: 500;
  font-size: 16px;
  color: variables.$color-text-primary;
  margin: 0;
}

@mixin font-heading-4 {
  font-size: 17px;
  font-weight: 500;
  color: variables.$color-text-primary;
  margin: 0;
}

@mixin roi-optimization-form-styles {
  .roi-optimization-form-row {
    display: flex;
    align-items: center;

    .roi-optimization-input-wrap {
      width: 50%;
      max-width: 350px;

      > div {
        margin: 0 0 8px;
      }
    }
  }

  .roi-form-separator {
    display: flex;
    width: 28px;
    align-self: flex-start;
    justify-content: center;
    padding-top: 29px;
  }
}

@mixin table-slide-out-details-button {
  :global(.ant-btn) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 4px 7px;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid variables.$color-border-main;
    background-color: white;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.07);

    &:hover {
      color: variables.$color-text-primary;
      border-color: color.adjust(variables.$color-border-main, $lightness: -15%);
      box-shadow: 0 3px 3px -1px rgba(0,0,0,0.1);
      background-color: white;

      :global(.ant-btn-icon) svg {
        fill: variables.$color-text-primary;
      }
    }

    :global(.ant-btn-icon) {
      margin-inline-end: 5px;
    }
  }
}

@mixin key-insights-panel-icon {
  display: flex;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 16px; // Sets the icon size
}

@mixin overview-headline-value-styles {
  font-size: 23px;
  letter-spacing: -0.03em;
  font-weight: 400;
  color: variables.$color-text-base;
}

@mixin overview-title-styles {
  font-size: variables.$font-size-medium;
  font-weight: 400;
  color: variables.$color-text-muted;
  line-height: 1;
}

@mixin filters-default-button {
  display: flex;
  align-items: center;
  height: variables.$filters-default-height;
  font-size: variables.$filters-font-size;
  font-weight: variables.$filters-font-weight;
  border-radius: 6px;
  padding: 0 10px;
  cursor: pointer;

  :global(.ant-btn-icon) {
    margin-inline-end: 6px !important;
    font-size: 12px;
  }

  &:hover {
    background-color: #F6F6F6;
    color: variables.$color-text-base !important;
    border-color: color.adjust(variables.$color-border-main, $lightness: -3%) !important;
  }
  
}

@mixin filters-text-button {
  display: flex;
  align-items: center;
  height: variables.$filters-default-height;
  font-size: variables.$filters-font-size;
  font-weight: variables.$filters-font-weight;
  border-radius: 6px;
  color: variables.$color-text-light;
  padding: 0 8px;
  cursor: pointer;

  :global(.ant-btn-icon) {
    margin-inline-end: 6px !important;
    font-size: 12px;
  }

  &:hover {
    border-color: transparent !important;
  }
}
