.missing-img-icon {
    display: block;
    font-size: 42px;
    color: rgba(217, 217, 217, 1);
}


.image-outer {
    position: relative;
    padding: 4px;
  
    .image-wrapper {
      position: relative;
      z-index: 1;
      padding: 2px;
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid rgba(217, 217, 217, 1);
      display: flex;
      align-items: center;
      justify-items: center;
      background: #fff;

    .image-container {
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        text-align: center;
        overflow: hidden;
        position: relative;

        img {
        width: auto;
        height: 44px;
        object-fit: contain;
        object-position: center;
        }
    }

    &.stack-base {
        position: absolute;
        z-index: 0;
        top: 8px;
        left: 0;
      }
    }

}

