@use '../../../styles/variables';

.alert {
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  margin-top: 12px;
  background-color: rgba(variables.$color-primary, 0.09);
  font-size: variables.$font-size-medium;
}

.selected-count {
  font-size: 14px;
  line-height: 32px;
  margin: 0 8px 0 0;
  color: variables.$color-text-light;
}

.bulk-update-container {
  padding-top: variables.$table-header-vertical-padding;
  margin-top: variables.$table-header-vertical-padding;
  border-top: 1px solid variables.$color-table-row-border;
  box-shadow: variables.$box-shadow-inner-xs;
  display: flex;
  align-items: start;
}
