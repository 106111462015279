@use '../../../styles/variables';

.metric {
  display: flex;
  justify-content: center;
  flex: 1;
  min-width: 170px;
  max-width: 25%;
  padding: 4px 18px;
  border-left: 1px solid #e2e4e7;
  margin-bottom: 20px;

  &:last-of-type {
    border-right: none;
  }
}

.metric-title {
  width: 100%;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px 0 6px;
  font-size: 10.75px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.metric-value {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  line-height: 1;
  font-weight: 400;
  color: variables.$color-text-primary;
  margin: 0;
  padding: 0;
}

.metric-delta {
  margin-top: 6px;
}
