@use '../../styles/variables';
@use '../../styles/mixins';

.value-change {
    border: 1px solid;
    border-radius: 50px;
    display: inline-flex;
    padding: 0 8px;
    align-items: center;
    gap: 6px;
    margin: 0 2px;
    font-size: 13px;
    font-weight: 500;

    &.positive {
        color: variables.$color-delta-green;
        border-color: rgba(variables.$color-delta-green, 0.2);
        background-color: rgba(variables.$color-delta-green, 0.04);
    }

    &.negative {
        color: variables.$color-delta-red;
        border-color: rgba(variables.$color-delta-red, 0.16);
        background-color: rgba(variables.$color-delta-red, 0.04);
    }
}

.green-text {
    color: variables.$color-delta-green;
}

.red-text {
    color: variables.$color-delta-red;
}

.red-text-bg {
    color: variables.$color-delta-red;
    background-color: rgba(variables.$color-delta-red, 0.07);
    border-radius: 4px;
    padding: 10px 16px;
    display: block;
}
