.color-picker-panel {
  :global(.ant-color-picker-presets) {
    border-top: 1px solid #f0f0f0;
    margin-top: 16px;
    padding-top: 8px;
  }

  &.single-preset {
    :global(
      .ant-color-picker-presets
        .ant-collapse
        .ant-collapse-item
        .ant-collapse-header
    ) {
      display: none !important;
    }
  }
}

.color-picker-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.02);
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
  }
}
