@use '../../styles/variables';

.fg-font-size-medium {
  font-size: variables.$font-size-medium;
}

.fg-font-weight-500 {
  font-weight: 500;
}

a {
  background-color: transparent;
  color: variables.$color-primary;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 500;

  &:hover {
    color: variables.$link-color;
    text-decoration: underline;
  }
}

aside a:hover {
  text-decoration: none;
}

// Resource Header Styles

.details-field-icon {
  color: variables.$color-details-icon;
  font-size: 20px;
  margin-right: 8px;
}

.details-field-text {
  color: variables.$color-details-field-text;
  font-size: 12.6px;
  font-weight: 500;
}
