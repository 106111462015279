@use '../variables';

.fg-btn-secondary {
  background: #f4f5f5;
  color: #a2a3a3;
  border: none;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    background: #eceded;
    color: #818282;
  }
}

.fg-icon-btn-secondary {
  background: #d7d7d7;
  color: white;
  border: none;
  line-height: 1.75;

  &:hover,
  &:active,
  &:focus {
    background: variables.$color-primary;
    color: white;
  }
}
