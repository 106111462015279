@use '../../../styles/variables';

%menu-base {
  :global(.anticon) {
    margin-right: 10px;
    font-size: 16px !important;
  }

  :global(.ant-dropdown-menu) {
    a {
      font-weight: 400 !important;
    }

    li:hover,
    a:hover {
      background: none !important;
      text-decoration: none;
      color: variables.$color-primary-hover;
    }
  }
}

.get-help-menu {
  @extend %menu-base;

  ul {
    min-width: 236px;
    padding: 8px 4px !important;
  }
}

.profile-menu {
  @extend %menu-base;

  ul:global(.ant-dropdown-menu) {
    padding: 10px 8px;
  }

  :global(.ant-dropdown-menu-item-icon).icon-circle {
    $circle-size: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: $circle-size;
    width: $circle-size;
    border-radius: 50%;
    background-color: #a0a3aa;
    color: #fff;
    font-size: 16px;
    margin-right: 10px;
  }

  li:first-child {
    padding-bottom: 13px;
  }

  li:nth-child(3) {
    margin-top: 9px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    line-height: 1;
    gap: 4px;

    &:hover {
      text-decoration: none;
    }

    %dropdown-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100%);
    }

    .organization-text {
      @extend %dropdown-text;

      font-size: 15px;
      color: variables.$color-text-muted;
    }

    .email-text {
      @extend %dropdown-text;

      font-size: 13px;
      color: variables.$color-text-light;
    }
  }

  :global(.ant-dropdown-menu-item-divider) {
    width: inherit;
    padding: 0;
    margin: 0 -18px;
  }
}
