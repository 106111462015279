@use '../../styles/variables';

.productInfo {
  margin-bottom: 16px;
}

.cogsTable {
  th {
    font-size: 12px;
    text-align: left;
    font-weight: normal;
  }

  th,
  td {
    padding: 8px 8px;

    &.amountCell {
      text-align: right;
    }

    &.dateCol {
      width: 134px;
    }
  }
}

.fieldMessage {
  font-size: 10px;
  margin-left: 4px;
  position: absolute;

  &.isError {
    color: variables.$danger-color;
  }
}

.total {
  font-size: 14px;
  font-weight: bold;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px 0 24px;
  margin: 0 -24px -12px -24px;
}

.until {
  display: block;
  width: 96px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  white-space: normal;
}

.metadata {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .label {
    color: #787c81;
  }

  svg {
    fill: #cccfd3;
    font-size: 16px;
  }
}

.sku {
  display: flex;
  align-items: center;
  gap: 4px;
}

.marketplaces {
  display: flex;
  gap: 4px;
  align-items: center;
}

.asin {
  svg {
    fill: #787c81;
  }

  display: flex;
  gap: 4px;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px;
  font-size: 14px;
}

.image-outer {
  padding: 2px;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;

  .image {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 2px;
    text-align: center;
    overflow: hidden;
    position: relative;

    img {
      width: auto;
      height: 44px;
      object-fit: contain;
      object-position: center;
    }
  }
}

.missing-img-icon {
  display: block;
  font-size: 42px;
  color: rgba(217, 217, 217, 1);
}

.cogs-cell {
  padding: 0 4px;
  height: 24px;
  font-size: 12.6px;
}

.modal-link {
  font-weight: 600;
}

.cogs-value {
  outline: none !important;
  border: 1px solid transparent !important;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.75);

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    border: 1px solid rgba(#000, 0.2) !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.75) !important;
  }
}

.bulk-upload-template-step {
  display: flex;
  gap: 8px;
  flex-direction: column;

  .bulk-upload-template-step-subtitle {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #d4d4d4;

    &:first-child::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 1px solid #d4d4d4;
    }
  }

  .bulk-upload-template-step-filters {
    display: flex;
  }

  .bulk-upload-template-step-action {
    display: flex;
    margin-top: 16px;
  }
}

:global(tr.ant-table-row) {
  &:hover,
  &:active,
  &:focus {
    .cogs-value {
      outline: none !important;
      border: 1px solid rgba(#000, 0.2) !important;
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }
}


.tabs {
  :global(.ant-tabs-nav) {
    padding: 0 !important;
    box-shadow: none !important;
  }
}
