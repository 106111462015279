@use '../../../../../styles/variables';

.cell-contents {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-align-cell-contents {
  display: flex;
  align-items: center;
  justify-content: last;

  .v-align-cell-inner-content {
    flex: 1;
  }
}

.total-row {
  font-weight: 600;
  background: variables.$color-table-total-row-bg !important;

  td {
    background: variables.$color-table-total-row-bg !important;
    position: sticky !important;
    top: 0;
    z-index: 3;
  }

  :global {
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      z-index: 4;
    }
  }
}
