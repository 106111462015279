@use '../../styles/mixins';

.full-content {
  @include mixins.full-content;
}

.spin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
