.resource-metadata {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 6px;

  .resource-metadata-item {
    margin: 0 20px 5px 0; // margin-bottom is for proper spacing when this wraps to 2 lines
    min-height: 24px; // Ideally this setting should be enforced in the header level, not in each individual item
    display: flex;
    align-items: center;
  }
}
