.create-record-table {
  :global(.ant-table-header-column) {
    width: 100%;
  }
}

.table-cell-input {
  width: 100%;

  :global(.ant-select-selection) {
    width: 100%;
  }
}

.field-error {
  border-color: red !important;

  :global(.ant-select-selection) {
    border-color: red !important;
  }

  :global(.ant-cascader-input) {
    border-color: red !important;
  }
}

.add-field-wrapper {
  position: relative;
  width: 100%;
}

.add-field {
  width: 100%;
  margin-right: 20px;

  :global(.ant-input-suffix) {
    right: 5px !important;
  }

  :global(.ant-btn.ant-btn-sm.ant-btn-icon-only) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.add-field-affix {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enter-icon {
  font-size: 0.8em;
}
