@use '../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  white-space: normal;
}

.text {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 2px;
  color: variables.$color-text-primary;
}

.image-outer {
  position: relative;
  padding: 4px;

  .image-wrapper {
    position: relative;
    z-index: 1;
    padding: 2px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-items: center;
    background: #fff;

    .image {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      text-align: center;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &.stack-base {
      position: absolute;
      z-index: 0;
      top: 8px;
      left: 0;
    }
  }

  .image-small {
    width: 42px;
    height: 42px;

    .image {
      width: 36px;
      height: 36px;
    }
  }

  .child-count {
    display: flex;
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 1;
    background: white;
    height: 19px;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-weight: 500;
    color: variables.$color-text-light;
    font-size: 11px;
    padding: 0 5px;
    line-height: 1;
    text-align: center;
    min-width: 20px;
    box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.05), -1px 2px 3px -1px rgba(0, 0, 0, 0.05);
  }
}

.missing-img-icon {
  display: block;
  font-size: 42px;
  color: rgba(217, 217, 217, 1);
}

.link {
  display: flex;
  align-items: center;
  gap: 6px;

  a {
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 1;
  }
}

.parent-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px 2px;
  line-height: 1;
  border-radius: 4px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.02);
  font-size: 12px;
}
