@use "../../../../styles/variables";

.error {
  color: variables.$danger-color;
  height: 15px;
}

.info {
  color: variables.$color-text-light;
  height: 15px;
}
