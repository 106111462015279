$button-size: 18px;

.label-tag {
  display: inline-flex;
  align-items: center;
  margin-right: 0.3rem;
  padding: 0 3px;
  border-radius: 2em !important;
  transition: all 200ms ease-in-out;
  height: 20px;

  &:hover {
    box-shadow: none !important;
    opacity: 0.9;
  }
}

.label-link {
  line-height: 1;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500 !important;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
}

.close-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: currentColor;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 0.2;
    }
  }

  &:global(.ant-btn-icon-only.ant-btn-sm) {
    height: $button-size;
    min-height: $button-size;
    width: $button-size;
    min-width: $button-size;
    line-height: $button-size;
    background-color: transparent;

    & > span {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
