.label-group--wrapper {
  padding: 5px 10px;
}

.label-group--color--wrapper {
  height: 20px;
  width: 20px;
}

.label-group--color--border {
  border-radius: 15px;
  padding: 2px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      left: #{($i - 1) * 8px};
    }
  }
}
