.drawer {
  :global(.ant-drawer-body) {
    padding: 0 24px;
  }
}

.drawer-list-item {
  h4,
  p {
    line-height: 1.4 !important;
  }

  h4 {
    font-size: 14.5px !important;
    margin-bottom: 2px !important;
  }

  strong {
    font-weight: 600 !important;
  }

  p {
    font-size: 13px !important;
    margin: 0 !important;
  }

  .new-feature-btn {
    border: 0;
    padding: 0;
  }

  :global(.ant-list-item-meta-avatar) {
    margin-right: 9px !important;
  }

  :global(.ant-list-item-action) {
    margin-left: 15px !important;
  }
}
