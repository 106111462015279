@use '../variables';

.ant-list {
  .ant-list-item {
    .ant-list-item-meta {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: 0;

      .ant-list-item-meta-title > a {
        color: variables.$color-primary !important;
      }
    }
  }
}
