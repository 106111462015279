@use '../variables';

.ant-tabs {
  height: 100%;

  > .ant-tabs-nav {
    background-color: white;
    padding: 0 variables.$content-horiz-padding;
    margin-bottom: 0 !important;
    border-color: #e5e5e5;
  }
}

.page-bg-white .ant-tabs-bar {
  padding: 0 20px;
}

.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-tab {
  padding: 8px 11px;
  margin: 0 5px 0 0 !important;
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: variables.$color-primary;
  }
}

.ant-tabs-content {
  .ant-tabs-tabpane-active {
    padding: variables.$content-horiz-padding;
  }
}

.ant-tabs-extra-content {
  line-height: 1em;
}

.ant-tabs-content-holder {
  background-color: variables.$color-background-tabs;
}
