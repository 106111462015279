@use '../../../styles/variables';

.paginated-table-wrapper {
    border: 1px solid rgba(0,0,0,0.06);
    border-radius: variables.$border-radius-card;
    overflow: hidden;
    transition: all 0.5s;
    background-color: white;

    &.table-loaded {
        border-color: variables.$color-border-container;
    }
}

.has-upper-content {
    border: none;
}

:global(.react-grid-item) .paginated-table-wrapper,
:global(.react-grid-item) .paginated-table-wrapper.table-loaded {
    border-color: variables.$color-table-row-border;
    border-radius: 4px;
}
