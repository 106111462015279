@mixin editable-cell-basic($backgroundColor: transparent, $borderColor: transparent) {
  outline: none !important;
  background-color: $backgroundColor !important;
  border: 1px solid $borderColor !important;
}

.editable-cell-positive {
  @include editable-cell-basic(rgba(73, 255, 150, 0.1), transparent);

  &:hover,
  &:focus,
  &:active {
    @include editable-cell-basic(rgba(73, 255, 150, 0.1), #aaa);
  }
}

.editable-cell-negative {
  @include editable-cell-basic(rgba(255, 220, 146, 0.3), transparent);

  &:hover,
  &:focus,
  &:active {
    @include editable-cell-basic(rgba(255, 220, 146, 0.3), #aaa);
  }
}

.editable-cell-zero {
  @include editable-cell-basic(rgba(0,0,0,0.03), transparent);

  &:hover,
  &:focus,
  &:active {
    @include editable-cell-basic(rgba(0,0,0,0.03), #aaa);
  }
}

:global(td.ant-table-cell) {
  &:hover,
  &:active,
  &:focus {
    .editable-cell-positive {
      @include editable-cell-basic(rgba(73, 255, 150, 0.1), #aaa);
    }

    .editable-cell-negative {
      @include editable-cell-basic(rgba(255, 220, 146, 0.3), #aaa);

      border: 1px solid #aaa !important;
    }

    .editable-cell-zero {
      @include editable-cell-basic(rgba(0,0,0,0.03), #aaa);

      border: 1px solid #aaa !important;
    }
  }
}
