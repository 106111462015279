@use '../../styles/variables';
@use '../../styles/mixins';

.react-grid-item.react-grid-placeholder {
  background: rgba(variables.$color-primary, 0.4);
}

.react-grid-item {
  @include mixins.box-shadow-surface-xs;

  background: white;
  overflow: hidden;
  transition: border-color 0.3s !important;
  border-radius: variables.$border-radius-card;
  border: 1px solid variables.$color-border-container;

  > .react-resizable-handle {
    width: 26px;
    height: 26px;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    transition: all 0.2s;

    &::after {
      right: 4px;
      bottom: 4px;
      width: 10px;
      height: 10px;
      border-color: variables.$color-primary;
    }

    &:active:hover::after {
      border-color: variables.$color-primary;
    }
  }

  &:hover {
    border-color: variables.$color-primary;

    &.react-resizable-hide {
      border-color: variables.$color-border-container; // This hides the purple border color on non-dashboard pages.
    }

    > .react-resizable-handle {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.react-draggable {
    .ant-card-head {
      cursor: grab;
      user-select: none;

      &:active {
        cursor: grabbing;
      }

      &::after {
        $drag-icon-color: variables.$color-primary;

        opacity: 0;
        content: '';
        position: absolute;
        transform: scale(1.23);
        top: 6px;
        left: calc(50% - 12px);
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $drag-icon-color;
        box-shadow:
          4px 0 0 $drag-icon-color,
          8px 0 0 $drag-icon-color,
          12px 0 0 $drag-icon-color,
          16px 0 0 $drag-icon-color,
          0 4px 0 $drag-icon-color,
          4px 4px 0 $drag-icon-color,
          8px 4px 0 $drag-icon-color,
          12px 4px 0 $drag-icon-color,
          16px 4px 0 $drag-icon-color;
        transition: opacity 0.4s;
      }

      &:hover::after {
        opacity: 1;
      }
    }

    &.react-draggable-dragging {
      .ant-card-head::after {
        transition: all 0.8s;
        background-color: variables.$color-primary;
        box-shadow:
          4px 0 0 variables.$color-primary,
          8px 0 0 variables.$color-primary,
          12px 0 0 variables.$color-primary,
          16px 0 0 variables.$color-primary,
          0 4px 0 variables.$color-primary,
          4px 4px 0 variables.$color-primary,
          8px 4px 0 variables.$color-primary,
          12px 4px 0 variables.$color-primary,
          16px 4px 0 variables.$color-primary;
      }
    }

    &:hover .ant-card-head {
      &::after {
        opacity: 1;
      }

      &:hover::after {
        opacity: 1;
      }
    }
  }

  .ant-card {
    border: none;
    box-shadow: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-card-head {
      width: 100%;
      flex-shrink: 0;
      border-bottom: none;
    }

    .ant-card-body {
      padding: 6px 24px 6px;
      max-height: calc(100% - 36px);
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;

      .ant-table td,
      .ant-table th {
        &:first-child {
          padding-left: 12px;
        }
      }

      // antd adds an extra "scroll
      .ant-table th:nth-last-child(2) {
        padding-right: 12px;
      }

      .ant-table td:last-child {
        padding-right: 12px;
      }

      .ant-table-body {
        overflow-y: auto !important;

        @include mixins.custom-scrollbar;
      }

      .ant-pagination {
        padding-right: 0;
      }

      .ant-table {
        height: calc(100% - 60px);
        padding: 0 !important;
      }

      .ant-table-content {
        overflow: scroll !important;
        height: 100%;
      }

      & > * {
        height: 100%;
        width: 100%;

        .ql-editor {
          overflow-y: initial;
        }

        @include mixins.custom-scrollbar;
      }
    }
  }
}
