@use '../../../styles/variables';

.cell {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-weight: 500;

  .root {
    color: variables.$color-text-primary;
    line-height: 17.01px;
    font-size: 12.6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tail {
    color: variables.$color-text-muted;
    line-height: 14.95px;
    font-size: 11.5px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
