@use '../mixins';

:global(.ant-card) {

  .ant-card-head {
    border-bottom: 1px solid transparent;
    min-height: 0;
  }

  .ant-card-head-title {
    padding: 12px 0 0;
    min-height: 20px;

    :global(.react-grid-item) & {
      padding: 12px 0 16px;
    }
  }

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-actions {
    width: 100%;
    background: #fff;

    li {
      margin-left: 16px;
      margin-right: 16px;
      pointer-events: none; // will need to revisit once exposed widget filters can be used
    }
  }
}
